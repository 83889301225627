import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Deadlifts 1-1-1-1-1 to a 1RM`}</p>
    <p>{`Max Height Box Jumps 1-1-1-1-1`}</p>
    <p>{`then,`}</p>
    <p>{`5 rounds for time of:`}</p>
    <p>{`5-Deadlifts (275/185)`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`15-T2B`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`All gym members and their families are invited to our annual
Christmas party this Saturday, December 10th at 5:00pm at the Barnett
home. We will have a chili contest with a trophy up for grabs! If your
last name begins with A-M please bring a side dish, N-Z please bring a
dessert. Please RSVP to let us know if you will be bringing chili and
how many are attending and we’ll send you the address. RSVP to:
Daniel\\@crossfitlouisvilleeast.com`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      